import { UserDocumentUploadDialogComponent } from './user-document-upload-dialog/user-document-upload-dialog.component';
import { UserDocumentType } from 'src/app/models/user/user-document/user-document-type.enum';
import { UserDocument } from 'src/app/models/user/user-document/user-document.model';
import { UserDocumentsService } from 'src/app/services/user-documents.service';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ApiError } from 'src/app/models/api/api-error.model';
import { MatDialog } from '@angular/material/dialog';

interface GroupedUserDocument {
  documents: UserDocument[];
  besAttachments: UserDocument[];
  atlAttachments: UserDocument[];
  certificates: UserDocument[];
}

@Component({
  selector: 'app-user-documents-data',
  templateUrl: './user-documents-data.component.html',
  styleUrls: ['./user-documents-data.component.scss'],
})
export class UserDocumentsDataComponent implements OnChanges {
  public loading: boolean = true;
  public isError: boolean = false;
  public error?: ApiError;

  @Input() userId?: string;
  @Input() startLoading: boolean = false;

  documents: UserDocument[] = [];
  groupedDocument: GroupedUserDocument = {
    documents: [],
    besAttachments: [],
    atlAttachments: [],
    certificates: [],
  };

  constructor(
    private dialog: MatDialog,
    private _userDocumentsService: UserDocumentsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['startLoading']?.currentValue &&
      !changes['startLoading']?.previousValue
    ) {
      this.getDocumentsData();
    }
  }

  async getDocumentsData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._userDocumentsService
      .getDocumentsListing(this.userId)
      .subscribe({
        next: (data) => {
          this.groupedDocument.documents = [];
          this.groupedDocument.besAttachments = [];
          this.groupedDocument.atlAttachments = [];
          this.groupedDocument.certificates = [];
          this.documents = data?.data?.map((json: any) => {
            const doc = UserDocument.fromJson(json);
            switch (doc.type) {
              case UserDocumentType.DOCUMENT:
                this.groupedDocument.documents.push(doc);
                break;
              case UserDocumentType.BES_ATTACHMENT:
                this.groupedDocument.besAttachments.push(doc);
                break;
              case UserDocumentType.SPORT_ATTACHMENT:
                this.groupedDocument.atlAttachments.push(doc);
                break;
              case UserDocumentType.CERTIFICATE:
                this.groupedDocument.certificates.push(doc);
                break;
            }
            return doc;
          });
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User Document Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }

  openUploadDialog() {
    const uploadDialogRef = this.dialog.open(
      UserDocumentUploadDialogComponent,
      {
        maxWidth: '480px',
        minWidth: '480px',
        data: {
          userId: this.userId,
        },
        autoFocus: false,
        restoreFocus: false,
      }
    );

    uploadDialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult === true) this.getDocumentsData();
    });
  }
}
