import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public overMinSize: boolean = false;
  private _minWidth: number = 1274;
  private _minHeight: number = 679;

  ngOnInit(): void {
    this.overMinSize =
      window.innerWidth > this._minWidth &&
      window.innerHeight > this._minHeight;
  }

  @HostListener('window:resize', ['$event'])
  onResize(e: any) {
    this.overMinSize =
      e.target.innerWidth > this._minWidth &&
      e.target.innerHeight > this._minHeight;
  }
}
