<div class="m-0 p-0" [ngStyle]="{'display': overMinSize ? 'block' : 'none'}">
  <router-outlet></router-outlet>
</div>
<div class="m-0 p-0" [ngStyle]="{'display': overMinSize ? 'none' : 'block'}">
  <app-screen-size-manager></app-screen-size-manager>
</div>
<!-- <ng-template *ngIf="overMinSize; then app else error"></ng-template>
<ng-template #app>
  <router-outlet></router-outlet>
</ng-template>
<ng-template #error>
  <app-screen-size-manager></app-screen-size-manager>
</ng-template> -->