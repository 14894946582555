import { ApiPagination } from 'src/app/models/api/api-pagination.model';
import { LimboService } from 'src/app/services/limbo.service';
import { ApiError } from 'src/app/models/api/api-error.model';
import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/models/user/user.model';

@Component({
  selector: 'app-limbo',
  templateUrl: './limbo.component.html',
  styleUrls: ['./limbo.component.scss'],
})
export class LimboComponent implements OnInit {
  @Input() page: number = 0;
  @Input() limit: number = 20;
  @Input() type?: string;
  @Input() enableMainBtn: boolean = false;
  @Input() titleMainBtn: string = '';
  @Input() linkMainBtn: string = '';
  @Input() linkMainBtnQueryParams: object = {};

  public isError: boolean = false;
  public loading: boolean = true;
  public pagination?: ApiPagination;
  public users: User[] = [];
  public error?: ApiError;
  private text?: string;

  tabIndex = 0;

  constructor(private _limboService: LimboService) {}

  onSelectedIndexChange(event: any) {
    this.tabIndex = event;
    this.getData();
  }

  ngOnInit(): void {
    this.getData();
  }

  onText(text: string) {
    this.text = text;
    this.getData();
  }

  onPage(page: number) {
    this.page = page;
    this.getData();
  }

  getData() {
    this.loading = true;
    this.isError = false;
    this.error = undefined;
    this._limboService
      .getListing({
        page: this.page,
        limit: this.limit,
        txt: this.text,
        set: this.tabIndex === 0 ? 'new' : 'old',
      })
      .subscribe({
        next: (data) => {
          this.users = data?.data?.map((json: any) => new User(json));
          this.pagination = new ApiPagination(data?.pagination);
        },
        error: (error) => {
          this.isError = true;
          this.error = new ApiError(error);
          console.error('User Listing Error: ', error);
        },
      })
      .add(() => (this.loading = false));
  }
}
