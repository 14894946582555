import { EnrollmentsListingParameters } from '../../constants/interfaces';
import { ApiResponse } from '../../models/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../../constants/api';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentService {
  constructor(private http: HttpClient) {}

  // Sostituisce i valori null con stringhe vuote
  private _removeNulls(obj: any): any {
    if (obj === null) return '';
    if (obj === undefined) return undefined;
    if (Array.isArray(obj)) {
      return obj
        .map((item) => this._removeNulls(item))
        .filter((item) => item !== undefined);
    } else if (typeof obj === 'object' && obj !== null) {
      return Object.keys(obj).reduce((acc, key) => {
        const value = this._removeNulls(obj[key]);
        if (value !== undefined) acc[key] = value;
        return acc;
      }, {} as any);
    }
    return obj;
  }

  getListing(p: EnrollmentsListingParameters) {
    return this.http.get<ApiResponse<any>>(API.enrollmentListing(p), {
      withCredentials: true,
    });
  }

  getDetails(id?: string) {
    return this.http.get<ApiResponse<any>>(API.enrollmentDetails(id), {
      withCredentials: true,
    });
  }

  getEnrollmentPDF(id: string) {
    return this.http.get(API.getEnrollmentPDF(id), {
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    });
  }

  patchEnrollment(id?: string, data?: any) {
    data ??= {};
    return this.http.patch<ApiResponse<any>>(
      API.enrollment(id),
      this._removeNulls(data),
      { withCredentials: true }
    );
  }

  patchEnrollmentCheckBox(id?: string, data?: any) {
    data ??= {};
    return this.http.patch<ApiResponse<any>>(API.enrollmentCheckBox(id), data, {
      withCredentials: true,
    });
  }
}
