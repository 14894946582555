<app-dea-widget-wrapper>
  <div class="row m-0 p-0">
    <div class="col m-0 p-0">
      <app-dea-search-bar (text)="onText($event)"></app-dea-search-bar>
    </div>
    <div class="col-auto m-0 p-0 ms-2">
      <button *ngIf="!loading" mat-flat-button color="primary" class="fw-bold px-3 py-1 rounded-pill"
        (click)="refreshData()">
        Aggiorna
      </button>
      <button *ngIf="loading" mat-flat-button class="fw-bold px-3 py-1 rounded-pill" disabled>
        Aggiorna <mat-icon><mat-spinner diameter="18"></mat-spinner></mat-icon>
      </button>
    </div>
  </div>
  <div class="v-spacer"></div>
  <ng-template *ngIf="loading; then loadingTrue else loadingFalse"></ng-template>
  <ng-template #loadingTrue>
    <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
      <mat-spinner strokeWidth="2" color="primary" diameter="40"></mat-spinner>
    </div>
  </ng-template>
  <ng-template #loadingFalse>
    <ng-template *ngIf="isError; then isErrorTrue else isErrorFalse"></ng-template>
    <ng-template #isErrorTrue>
      <div class="d-flex justify-content-center align-items-center" style="height: 96px;">
        {{error?.message}}
      </div>
    </ng-template>
    <ng-template #isErrorFalse>
      <div class="d-flex flex-column gap-3 mb-3">
        <div *ngFor="let enrollment of enrollments;" class="row mx-2">
          <div class="fw-bold col-auto m-0 p-0 my-auto" style="width: 100px;">{{enrollment.shortHash}}</div>
          <div class="col my-auto">ST: {{enrollment.getFirstUserFullName()}}</div>
          <div class="col my-auto">{{enrollment.dipartimento}}</div>
          <div class="col m-0 p-0 ms-auto text-end my-auto">{{enrollment.createdAt | toItDateDdMmmmYyyy}}</div>
          <div class="col-auto m-0 p-0 ms-4 d-flex flex-column justify-content-center gap-1">
            <div class="fs-075r d-flex flex-row align-items-center gap-1">
              <mat-checkbox class="custom-checkbox" color="primary" [labelPosition]="'after'"
                (change)="onChangeCheckbox(enrollment, 'segreteria_didattica')"
                [checked]="enrollment.segreteria_didattica === true">
              </mat-checkbox>
              <div>Segreteria Didattica</div>
            </div>
            <div class="fs-075r d-flex flex-row align-items-center gap-1">
              <mat-checkbox class="custom-checkbox" color="primary" [labelPosition]="'after'"
                (change)="onChangeCheckbox(enrollment, 'segreteria_amministrativa')"
                [checked]="enrollment.segreteria_amministrativa === true">
              </mat-checkbox>
              <div>Segreteria Amministrativa</div>
            </div>
          </div>
          <div class="col-auto m-0 ms-4 p-0 my-auto">
            <div class="h-full d-flex flex-column justify-content-center">
              <mat-icon class="m-0 p-0 col-auto opacity-25 dea-mat-icon-btn" [matMenuTriggerFor]="menu"
                title="Modifica {{enrollment?.shortHash}}">
                more_vert
              </mat-icon>
              <mat-menu #menu="matMenu">
                <button mat-menu-item class="dea-mat-icon-btn" (click)="goToEnrollmentFormPage(enrollment.id)"
                  title="Modifica {{enrollment?.shortHash}}">
                  <mat-icon>edit</mat-icon>
                  <span>Modifica</span>
                </button>
                <button mat-menu-item class="dea-mat-icon-btn" (click)="openPreviewPDF(enrollment.id)"
                  title="Visualizza {{enrollment?.shortHash}}">
                  <mat-icon>visibility</mat-icon>
                  <span>Visualizza</span>
                </button>
                <button mat-menu-item class="dea-mat-icon-btn" (click)="downloadEnrollmentPDF(enrollment.id)"
                  title="Scarica {{enrollment?.shortHash}}">
                  <mat-icon>download</mat-icon>
                  <span>Scarica</span>
                </button>
                <button mat-menu-item class="dea-mat-icon-btn" (click)="printEnrollmentPDF(enrollment.id)"
                  title="Stampa {{enrollment?.shortHash}}">
                  <mat-icon>printer</mat-icon>
                  <span>Stampa</span>
                </button>
                <hr>
                <button disabled mat-menu-item class="dea-mat-icon-btn" (click)="printEnrollmentPDF(enrollment.id)"
                  title="Crea utenti Ring da {{enrollment?.shortHash}}">
                  <mat-icon>group_add</mat-icon>
                  <span>Crea utenti Ring</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
      <app-listing-pagination-bar [paginationData]="pagination" (pageEmitter)="onPage($event)">
      </app-listing-pagination-bar>
    </ng-template>
  </ng-template>
</app-dea-widget-wrapper>